<template>
<!-- 用户注册登录头部-重置密码 -->
<div class="resgister">
	<!-- <header v-if="isApp" class="app">
		<div class="app-header-container" id="appHeader">
			<div class="plat-info">
				<div class="left">
					<img :src="platInfo.logo_url" @click="$utils.goIndexPage" class="logo" />
					<span>{{platInfo.name}}</span>
				</div>
				<img src="@/assets/img/login/user.png" @click="$router.push('/loginIndex')" class="icon-user" >
			</div>
			<div class="welcome-text-info">
			  <div class="welcome" v-if="loginInfo.disseminate_type=='text'">
			    {{loginInfo.text}}
			  </div>
			  <div class="left" v-if="loginInfo.disseminate_type=='img'">
			    <img :src="loginInfo.img.attachment_path" >
			  </div>
			</div>
		</div>
	</header> -->
	<header v-if="isApp" class="app">
		<div class="app-header-container" id="appHeader">
			<div class="header-info">
				<div class="plat-info">
					<div class="left">
						<img :src="platInfo.logo_url" @click="$utils.goIndexPage" class="logo" />
						<span>{{platInfo.name}}</span>
					</div>
					<img src="@/assets/img/login/user.png" @click="$router.push('/loginIndex')" class="icon-user" >
				</div>
				<div class="welcome-text-info">
				  <div class="welcome" v-if="loginInfo.disseminate_type=='text'">
				    {{loginInfo.text}}
				  </div>
				  <!-- <div class="left" v-if="loginInfo.disseminate_type=='img'">
				    <img :src="loginInfo.img.attachment_path" >
				  </div> -->
				</div>
			</div>
			<el-carousel indicator-position="outside" class="carousel-box" height="100%" :interval="3000">
			  <el-carousel-item v-for="(item,index) in loginImgArr" :key="index">
			    <img :src="item.attachment_path"  style="height: 100%;width:100%;object-fit: cover;">
			  </el-carousel-item>
			</el-carousel>
		</div>
	</header>
  <div class="header" v-else>
    <div class="content">
      <div class="plat-info">
        <img :src="platInfo.logo_url" class="logo" @click="$utils.goIndexPage"/>
        <span>{{platInfo.name}}</span>
      </div>
      <!-- 宣传语 -->
      <div class="welcome-box">
        <div class="welcome" v-if="loginInfo.disseminate_type=='text'">
          {{loginInfo.text}}
        </div>
        <div class="left" v-if="loginInfo.disseminate_type=='img'">
          <img class="welcome-logo" :src="loginInfo.img.attachment_path" >
        </div>
      </div>
      <!-- <div class="login">
        <span>已有账号？</span>
        <label @click="$router.push('/login')">请登录></label>
      </div> -->
    </div>
  </div>
</div>
</template>

<script>
	import { wxShareConfige, shareWXQQ, isMobile } from "@/utils/wxConfig.js"; 
	export default {
    name:'loginHeader',
		data(){
			return {
        platInfo:{},
				loginImgArr:[],
        loginInfo:{
          img:{},
        },
				isApp:false,
			}
		},
		created(){
			this.isApp = isMobile();
      this.getLoginSet();
      this.$utils.getPlatformInfo().then(res=>{
        this.platInfo = res.data;
      })
		},
		mounted(){

		},
		methods: {
      getLoginSet(){
        this.$axios.get("/setting/public/reglogin") .then((res) => {
            if (res.code == 200) {
              let data = res.data,loginImgArr = [];
              data.map(item=>{
                //头部欢迎语
                if(item.key == 'login_reg_base_setting'){
                  this.loginInfo = item.value;
                }
								//背景图片
								if(item.key == 'login_reg_pic_coll'){
								  let dataArr = item.value.items;
								  for(let i=0,len=dataArr.length;i<len;i++){
								    if(dataArr[i].status == 1){
								      loginImgArr.push(dataArr[i])
								    }
								  }
								}
              });
							if(loginImgArr.length>0){
							  this.loginImgArr =  loginImgArr
							}
            }
          })
          .catch((err) => {
            console.log(err);
            err.msg && this.$message.error(err.msg || "系统错误,联系管理员");
          });
      },
		},
	}
</script>

<style scoped lang="scss">
.app-header-container{
	font-size: calc( 100vw / 750 * 20);
	
	box-sizing: border-box;
	height: 20.5rem;
	.header-info{
		position: relative;
		padding: 1.5em 1em;
		z-index: 5;
	}
	.carousel-box{
		position: absolute;
		top:0;
		left:0;
		height: 20.5rem;
		width: 100vw;
		z-index: 3;
	}
	.plat-info{
		display: flex;
		font-size: 1.6em;
		color:#FFFFFF;
		height: 3.2em;
		align-items: center;
		justify-content: space-between;
		.left{
			display: flex;
			align-items: center;
			.logo{
				width:3em;
				margin-right:1em;
				height:auto;
			}
		}
		.icon-user{
			width:1em;
			height:1em;
		}
	}
	.welcome-text-info{
		margin-top: 1em;
		font-size: 1.8em;
		color:#FFFFFF;
		text-align: center;
		
		.left{
			img{
				height:2em;
				width:auto;
			}
		}
	}
	.login-app{
		text-align: right;
		font-size: 1.3em;
		color:#666;
		.point{
			color:#F72626;
		}
	}
}
.resgister{
  // 导航头部
   .header {
    width: 100%;
    height: 64px;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    .content {
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;
      .plat-info{
        display: flex;
        align-items: center;
        font-size: 20px;
        .logo{
          height:30px;
          margin-right:12px;
        }
      }
      .welcome-box{
        font-size: 20px;
      }
      .welcome-logo{
        height:40px;
        width:auto;
        margin-left:12px;
      }
      .login {
        position: absolute;
        right: 0;
        bottom: -30px;
        font-size: 16px;
        color: #333333;
        flex:0;
        > label {
          color: #f74848;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
