<template>
<div>
  <header></header>
	<div class="container-main" :class="{isApp:isApp}">
    <div v-html="content"></div>
  </div>
</div>
</template>

<script>
  import header from './components/header.vue';
	export default {
    components:{
      header,
    },
		data(){
			return {
        content:'',
				isApp:true,
			}
		},
		created(){
      this.getData();
			this.isApp = this.isMobile();
		},
		mounted(){
			if(this.isApp){
				// 1rem  相当于750尺寸设计图的 20px;
				//兼容pc显示
				(function(){
					var html=document.querySelector('html');
					html.style.fontSize=window.innerWidth/750 * 20 +'px'
					window.onresize=function(){
						html.style.fontSize=window.innerWidth/750 * 20 +'px'
					}
				})();
				var fun = function (doc, win) {
				    var docEl = doc.documentElement,
				        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
				        recalc = function () {
				            var clientWidth = docEl.clientWidth; 
				            if (!clientWidth) return;
				            //这里是假设在750px宽度设计稿的情况下，1rem = 100px；
				            //可以根据实际需要修改
				            docEl.style.fontSize = 20 * (clientWidth / 750) + 'px';
				        };
				    if (!doc.addEventListener) return;
				    win.addEventListener(resizeEvt, recalc, false);
				    doc.addEventListener('DOMContentLoaded', recalc, false);
				}
				fun(document, window)
			}
		},
		methods: {
			isMobile() {
			  var userAgentInfo = navigator.userAgent;
			  var mobileAgents = [ "Android", "iPhone", "SymbianOS", "Windows Phone", "iPad","iPod"];
			  var mobile_flag = false;
			  for (var v = 0; v < mobileAgents.length; v++) {
			      if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
			          mobile_flag = true;
			          break;
			      }
			  }
			   var screen_width = window.screen.width;
			   var screen_height = window.screen.height;   
			   if(screen_width < 500 && screen_height < 800){
			       mobile_flag = true;
			   }
			   return mobile_flag;
			},
      getData(){
        let query = this.$route.query;
        if(query.id){
           this.$axios.postJSON('/setting/agreement/detail',{id:Number(query.id)}).then(res=>{
             if(res.code == 200){
              let data = res.data;
               this.content = data.content;
             }
           })
        }else{
          this.$message.error("获取参数失败！")
        }
        //
      }
		},
	}
</script>

<style scoped lang="scss">
.container-main{
  width:900px;
  margin:50px auto 0;
  background-color: #FFFFFF;
  padding: 30px 100px;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: calc(100vh - 220px);
	&.isApp{
		margin-top:0;
		width:100vw;
		padding:1.5rem 1.5rem;
		*{
			font-size: 1.4rem;
		}
	}
}
</style>
